.foot {
    background: #000;
}

.foot .container .logo-f {
    width: 5em;
    border-radius: 20px;
    margin-left: 2rem;
}
.foot .container .icone ul {
    display: inline-flex;
    list-style-type: none;
    justify-content: left;
    margin: .5em;
}
.foot .container .icone ul li {
    
    margin-right: .6em;
    color: white;
}
.foot .container .icone ul li a {
    transition: .1s ease-in;
    color: white;
}
.foot .container .icone ul li a:hover {
    transition: .1s ease-in;
    color: #00b8a5;
}

.c ul {
    margin-left: 1.5rem;
}

.c h3 {
    margin-left: 2rem;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

.c ul li {
    color: white;
    font-size: 14px;
    list-style-type: none;
}

.c ul li a{
    color: white;
    font-size: 14px;
    text-decoration: none;
    list-style-type: none;
    transition: .1s ease;
}

.c ul li a:hover{
    color: #00b8a5;
    transition: .1s;
}

.copy {
    padding: .8em 0;
    font-size: 12px;
    text-decoration: none;
}
.copy a {
    text-decoration: none;
    color: #027e72;
}

.chat {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: #027e72;
    border-radius: 90px;
    width: 3rem;
    height: 3rem;
    display: grid;
    place-content: center;
    place-items: center;
    box-shadow: 2px 5px 14px grey;
    border: 1px solid rgb(175, 198, 255);

}

.chat a {
    color: white;
    margin: auto!important;
    font-size: 22px;
}