*
{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}
.carcontainer
{
    padding: 40px 50px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #9a9a9a;
}
.carcontainer h2
{
    font-size: 42px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    color: #010f1c;
}
.carcontainer .carrinhovazio
{
    margin-top: 30px;
    margin-left: 40%;
}
.carcontainer .carrinhovazio h2
{
    font-size: 32px;
    color: #010f1c;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 30px;
}
.carcontainer .carrinhovazio button
{
    border:5px solid #027e72;
    padding: 10px 20px;
    cursor: pointer;
    background: #027e72;
    border-radius: 5%;
    margin-left: 70px;        
}
.carcontainer .carrinhovazio .btn
{
    text-decoration: none;
    color: #fff;
}

.carcontainer .conteudo .item_carrinho
{
    background: #f1f1f1;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.carcontainer .conteudo .item_carrinho .box_img 
{
   display: grid;
   place-content: center;
   place-items: center;
}
.carcontainer .conteudo .item_carrinho .box_img img
{
    width: 250px;
    margin: auto !important;
    max-width: 10em;
}
.carcontainer .item_carrinho .detalhe
{
    display: flex;
    padding: 10px 15px;
}
.carcontainer .item_carrinho .detalhe .info h4
{
    
    text-transform: uppercase;
    font-size: 16px;
    color: #9a9a9a;
    font-weight: 100;
    margin-top: 5px;
    letter-spacing: 1.5px;
}
.carcontainer .item_carrinho .detalhe .info h3
{
    margin-top: 20px;
    color: #010f1c;
    font-size: 22px;
    font-weight: 200;
}
.carcontainer .item_carrinho .detalhe .info p
{
    margin-top: 10px;
    color: #010f1c;
    letter-spacing: 1px;
}
.carcontainer .conteudo .item_carrinho  .detalhe .info .quantidade
{
    margin-top: 2px;
    display: flex;
}
.carcontainer .conteudo .item_carrinho  .detalhe .info .quantidade .mais
{
    background: #000;
    outline: none;
    border: 1px solid white;
    color: white;
    height: 1.3rem;
    width: 1.3rem;
    display: grid;
    place-content: center;
    place-items: center;
    border-radius: 90px;
    margin-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    cursor: pointer;
}
.carcontainer .conteudo .item_carrinho  .detalhe .info .quantidade .menos
{
    background: #000;
    outline: none;
    border: 1px solid white;
    color: white;
    height: 1.3rem;
    width: 1.3rem;
    display: grid;
    place-content: center;
    place-items: center;
    border-radius: 90px;
    margin-right: 1rem;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 20px;
    cursor: pointer;
}

.carcontainer .conteudo .item_carrinho  .detalhe .info .total
{
    color: #010f1c;
    font-size: 22px;
    margin-top: 10px;
}
.carcontainer .conteudo .item_carrinho  .detalhe .fechar
{
    margin-top: 30px;
    margin-left: 50px;
}
.carcontainer .conteudo .item_carrinho  .detalhe .fechar button
{
    border: none;
    outline: none;
    border: none;
    font-size: 22px;
    color: #010f1c;
    background: none;
    cursor: pointer;
}
.carcontainer .precototal
{
    margin-top: 30px;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.carcontainer .checkout
{
    margin-top:20px;
    padding: 10px 20px;
    color: #fff;
    height: 3rem;
    border: none;
    outline: none;
    border: 2px solid #027e72;
    background: #027e72;
    cursor: pointer;
}
.carcontainer .checkout:hover
{
    margin-top:20px;
    padding: 10px 20px;
    color: #027e72;
    border: 2px solid #027e72;
    outline: none;
    background:transparent;
    cursor: pointer;
}

.remover {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 2.5rem;
    width: 2.5rem;
    transition: all .2s ease-in;
}


.remover:hover,
.remover:focus {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    transition: all .3s ease-out;
    height: 2.5rem;
    width: 2.5rem;
    color: white!important;
    background: red!important;
    border-radius: 90px;
}

.cc{
    width: 100%;
}

@media (min-width: 760px) {
    
   
.cc{
    max-width: 70vw;
} 
    
}


.login-btn {
    border-radius: 0 !important;
}

.fs-icon {
    font-size: 60px;
    margin: 3.5rem auto;
}

.carcontainer a {
    text-decoration: none;
}