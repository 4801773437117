*
{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}
.sobre
{
    padding: 30px 10px;
    width: 100%;
}


.sobre h2
{
    font-size: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    color: #071c01;
    border-bottom: 1px solid grey;
    margin-bottom: 20px;
}



.sobre h3
{
    font-size: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    color: #0d1c01;
    margin-top: 3rem;
 
    margin-bottom: 20px;
}


.btn-primary {
    background: #027e72;
    border-color: #027e72;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:focus-within {
    background: #005e54!important;
    border-color: #005e54!important;
}

.form-control:hover,
.form-control:focus-visible,
.form-control:focus-within {
    border: 1.5px solid #027e72;
    border-radius: 0;
    box-shadow: none!important;
}


.form-control {
    border: 1.5px solid #d9d9d9;
    border-radius: 0;

}

