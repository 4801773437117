  *{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
  }
  html, body  {
    overflow-x: hidden!important;
  }
  

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: none;

  }

  .text-primary {
    color: #027e72!important;
  }

  ::-webkit-scrollbar-thumb {
    background: #44c497;
    border-radius: 90px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #027e72;
    border-radius: 90px;
  }


.banner_pub
  {
    display: grid;
    place-content: center;
    place-items: center;
    height: 60vh;
    padding: 30px 40px;
    width: 100%;
    background: linear-gradient(180deg, #000000a8, #00000049, #0000006b, #000000cb), url(../../img/banner6.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  
  
.banner_pub .container span 
  {

    font-size: 50px;
    font-weight: bolder;
    color: #fff;

  }


  .plus:hover{
    background-color: white;
    transition: all .2s ease-out;
    /*box-shadow: 2px 4px 9px rgb(149, 214, 254);*/
  }

  
.plus {
  background: none;
  border: 2px solid white;
  color: white;
  font-weight: bold;
  transform: scale(1.05);
  transition: all .2s ease;
  /*box-shadow: 2px 4px 9px rgb(149, 214, 254);*/
}


.top_banner .containe .detalhe h2
{
font-size: 45px;
color:#010f1c;
margin-right: 20%;
text-align: center;
margin-bottom: 40px;
}
.top_banner .containe .detalhe button
{
    border:5px solid #272b2e;
    padding: 10px 20px;
    cursor: pointer;
    background: #027e72;
    border-radius: 5%;
}
.container1 {
    display: flex;
    justify-content: center;
    margin-right: 200px;
}
.container1 .link{
    color:#010f1c;
    text-decoration: none;
}
.container1 .link
{
    color: #e3edf6;
}



.destaque .container-lg
{
display: flex;
max-width: 100vw;
justify-content: space-between;
overflow-x: scroll!important;
margin-top: 1rem;
}

.destaque .container-lg .box
{
  padding: 10px 20px;
  margin-left: 10px;
}
.destaque .container-lg .box .box_img
{
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: #e8f4ff;
  overflow: hidden;
  cursor: pointer;
      
}
.destaque .container-lg .box .box_img img
{
  height: 80px;
  width: 80px;
  align-items: center;
  max-width: 100%;
  justify-content: center;
  margin-top: 50px;
  margin-left: 55px;
  transition: 0.5s;
}
.destaque .container-lg .box .box_img:hover img
{
  transition: all .3s ease;
  transform: scale(1.3);
}

/*Parte do design dos cards dos produtos*/

.produto h2
{
  font-size: 32px;
  color: #010f1c;
  margin-bottom: 20px;
  font-weight: bolder;
}


.produto .container-flui .box
{
  margin: 1rem 0;
  padding: 10px 20px;
  overflow: hidden;
  height: auto;
  border: 1px solid #e2e0e0;
  transition: 0.5s;
  cursor:pointer;
  background: white!important;
  position: relative;
  border-radius: 12px;
}
.produto .container-flui .box:hover
{
 box-shadow: 2px 6px 9px #00000059;
}


.produto .container-flui .box .img_box
{
  display: flex;
  justify-content: center;
  padding: 5px;
}
.produto .container-flui .box .img_box img
{
  width: 100%;
  transition: all .2s ease-in;
}


.produto .container-flui .box:hover img
{
  transition: all .2s ease;
  transform: scale(1.05);
}

.produto .container-flui .box .img_box .icone
{
  z-index: 8;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: absolute;
  right: 1em;
  border-radius: 10px;
  box-shadow: 2px 4px 12px rgb(223, 249, 255);
  transition: 0.3s;
  transform: translateX(100px);
}

.produto .container-flui .box:hover .icone
{
  transform: translateX(0px);
}

/*
@media (max-width: 700px) {
  
  .produto .container-flui .box:hover .icone
  {
    transform: translateX(-160px);
  }
  }
*/



.produto .container-flui .box .img_box .icone li
{
  list-style: none;
  padding: 10px 10px;
  color: #010f1c;
  cursor:pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;
  transition: 0.2s;
  font: 18px;
}
.produto .container-flui .box .img_box .icone li:hover
{
  background:#027e72;
  color:#fff
}
.produto .container-flui .box .detalhe
{
  margin-top: 10px;
}
.produto .container-flui .box .detalhe p
{
  color: #9a9a9a;
  font-size: 14px;
}
.produto .container-flui .box .detalhe h3
{
  font-size: 22px;
  font-weight: bold;
  margin-top: 5px;
  color: #010f1c!important;
  transition: 0.5s;
}
.produto .container-flui .box .detalhe h4
{
  margin-top:5px;
  font-size: 14px;
  color:#027e72;
  letter-spacing: 1px;
}


.banner .containe
{
  padding: 20px 30px;
  background: #f8f8f8!important;
  border-radius: 20px;
  box-shadow: 1px 4px 14px rgb(205, 205, 205);
}
.banner .containe
{
  margin-top: 10px;
  margin-bottom: 15px;
}
.banner .containe .detalhe
{
  margin-top: 20px;
}
.banner .containe .detalhe h3
{
  font-size: 45px;
  color: #010f1c;
  font-weight: bolder;
  margin-bottom: 10px;
}
.banner .containe .detalhe h6
{
  font-size: 16px;
  color: #010f1c;
  margin-bottom: 10px;
}
.banner .containe .detalhe p
{
  font-size: 20px;
}
.banner .containe .detalhe button
{
  border:5px solid #027e72;
  padding: 10px 20px;
  cursor: pointer;
  background: #027e72;
  border-radius: 5%;
  margin-top: 10px;

}
.banner .containe .detalhe .link
{
color: #fff;
text-decoration: none;
}
.banner .containe .img_box img
{
  height: 325px;
  margin: 10px 4px;
}


.detalhe_produto .container-fluid
{
  overflow-x: hidden;
    width: 100vw;
    position: fixed;
    height: 100vh!important;
    background: #fff;
    padding: 5px;
    top: 0;
    left: 0;
    z-index: 9!important;
    border-radius: 1px;
}

.detalhe_produto .container-fluid .btnfechar
{
    border: none;
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    outline: none;
    background: #027e72;
    font-size: 20px;
    color: #fff;
    padding: 5px 15px;
    border-radius: 60px;
    cursor: pointer;
}


.detalhe_produto .boxproduto  img
{
max-width: 10em!important;
}



.detalhe_produto .container-fluid .boxproduto .detalhe h4
{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 200;
    color: #9a9a9a;
    letter-spacing: 1px;
}
.detalhe_produto .container-fluid .boxproduto .detalhe h2
{
    margin-top: 20px;
    font-size: 32px;
    color: #010f1c;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.detalhe_produto .container-fluid .boxproduto .detalhe p
{
    margin-top: 10px;
    color: #4a4a4a;
}
.detalhe_produto .container-fluid .boxproduto .detalhe h3
{
    margin-top: 10px;
    color: #010f1c;
    font-size: 32px;
    letter-spacing: 1px;
}
.detalhe_produto .container-fluid .boxproduto .detalhe button
{
    margin-top: 30px;
    padding: 8px 18px;
    font-size: 16px;
    color: #fff;
    background: #027e72;
    border: 2px solid #027e72;
    outline: none;
    cursor: pointer;
    transition: all .2s ease-in;
    border-radius: 5px;
    font-weight: bold;
}


.btn-outline:hover {
  
  color: white!important;
  background: #027e72!important;
  border: 2px solid #027e72!important;
  outline: none!important;
  cursor: pointer!important;
  transition: all .2s ease-in;
  border-radius: 5px!important;
  font-weight: bold!important;

}

.btn-outline 
{
  background: transparent!important;
  border: 2px solid #027e72!important;
  transition: all .2s ease-out!important;
  color: #027e72!important;
  font-weight: bold!important;
  }

.text-warning {
  color: #027e72 !important;
}
.detalhe_produto .container-fluid .boxproduto .detalhe button:hover
{
background: transparent;
border: 2px solid #027e72;
transition: all .2s ease-out;
color: #027e72;
font-weight: bold;
}



@media (max-width: 300px) {
 

  .produto .container-flui .box:hover .icone
  {
    transform: translateX(-210px) !important;
  }
  .produto .container-flui .box
  {
    width: 90vw!important;
    margin-left: -2.2rem;
  }

  
.produto .container-flui .box:hover img
{
  transition: all .1s ease;
  transform: scale(1)!important;
}

}

@media (max-width: 400px) {
         
/*
  .produto .container-flui .box:hover .icone
  {
    transform: translateX(-160px) !important;
  }
*/
  .produto .container-flui .box
  {
    width: 80vw!important;
    margin: 1rem auto !important;
   /* margin-left: -2.2rem;*/
  }

  
.produto .container-flui .box:hover img
{
  transition: all .1s ease;
  transform: scale(1)!important;
}

}

  @media (max-width: 500px) {
    .w-sm-100 {
      width: 107%!important;
      margin: 6px auto!important;
    }


    .btns-buy {
      width: 100%;
      display: inline !important;
    }
    
  /*
    .produto .container-flui .box:hover .icone
    {
      transform: translateX(-160px);
    }
*/
.login-btn {
  /* display: none!important; */
}
.login-btn .texto {
  display: none!important;
  
}

  .destaque .container-lg
  {
  display: flex;
  width: 100vw;
  max-width: 100vw;
  justify-content: space-between;
  gap: 20px;
  overflow-x: auto;
  }

  .destaque .container-lg .box
  {
    padding: 5px 10px;
    display: grid;
    place-content: center;
    place-items: center;
     border-radius: 50%;
    background: #e8f4ff;
    cursor: pointer;
    height: 6em;
    width: 6em;
    
  }

  .destaque .container-lg .box .box_img
  {
    height: 100px;
    width: 100px;
   
        
  }
  .destaque .container-lg .box img
  {
    height: 4em;
    width: 4em;
    
    transition: 0.5s;
  }
  .destaque .container-lg .box:hover img
  {
    transition: all .3s ease;
    transform: scale(1.05);
  }
}

.d-t h3 {
  font-size: 16px;
  font-weight: bold;
}

.swal2-confirm {
  background: #027e72!important;
  border-radius: 0!important;
  border: 2px solid #027e72 !important;
  box-shadow: none!important;
}

.swal2-title{
  color: #027e72;
}

.swal2-cancel {
  background:  transparent !important;
  border-radius: 0!important;
  color: #027e72 !important;
  border: 2px solid #027e72 !important;
}


.swal2-icon {
  color: #027e72!important;
}



.d_s {
  display: flex!important;
  place-content: center;
  place-items: center;
  color: #027e72;
  justify-content: center;
}